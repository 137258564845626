import { getFunctions, httpsCallableFromURL } from "firebase/functions";

const FUNCTION_URL_MAP: Record<string, string> = {
  startTellMeMore: "https://starttellmemmore-3vmt5csw7a-uc.a.run.app",
};

export const functions = getFunctions();

export const callable = (urlOrName) => {
  const url = FUNCTION_URL_MAP[urlOrName] || urlOrName;
  return httpsCallableFromURL(functions, url);
};
