import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  User,
} from "firebase/auth";
import { app } from "./app";

const auth = getAuth(app);

const getCurrentUser = () => {
  return new Promise<User>((res, rej) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        res(user);
      } else {
        // This will retrigger the above callback
        signInAnonymously(auth).catch(rej);
      }
    });
  });
};

export { auth, getCurrentUser };

export * as fbAuth from "firebase/auth";
